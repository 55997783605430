<template>
  <div>
    <fieldset>
        <legend>Direcciones</legend>
        <div class="accordion mb-4" id="listaDirecciones">
            <div v-for="(tmp_direccion, index) in direcciones" :key="tmp_direccion.id" class="card">
                <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                        <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#collapse'+index" aria-expanded="true" :aria-controls="'collapse'+index">
                            {{ tmp_direccion.calle }}
                        </button>
                    </h2>
                </div>

                <div :id="'collapse'+index" :class="'collapse'+(index == 0 ? ' show' : '')" aria-labelledby="headingOne" data-parent="#listaDirecciones">
                    <div class="card-body">
                        <div class="row form-group">
                            <label for="direccion_calle" class="col-form-label col-sm-3">Calle</label>
                            <div class="col-sm-9"><input v-model="tmp_direccion.calle" type="text" name="direccion_calle" :id="'direccion_calle'+index" class="form-control"></div>
                        </div>
                        <div class="row form-group">
                            <label class="col-form-label col-sm-3">Número</label>
                            <div class="col"><input v-model="tmp_direccion.no_interior" type="text" name="direccion_numero_interior" :id="'direccion_numero_interior'+index" class="form-control" placeholder="Número interior"></div>
                            <div class="col"><input v-model="tmp_direccion.no_exterior" type="text" name="direccion_numero_exterior" :id="'direccion_numero_exterior'+index" class="form-control" placeholder="Número exterior"></div>
                        </div>
                        <div class="row form-group">
                            <label for="direccion_colonia" class="col-form-label col-sm-3">Colonia</label>
                            <div class="col-sm-9"><input v-model="tmp_direccion.colonia" type="text" name="direccion_colonia" :id="'direccion_colonia'+index" class="form-control"></div>
                        </div>
                        <div class="row form-group">
                            <label for="direccion_delegacion_municipio" class="col-form-label col-sm-3">Delegación / Municipio</label>
                            <div class="col-sm-9"><input v-model="tmp_direccion.delegacion" type="text" name="direccion_delegacion_municipio" :id="'direccion_delegacion_municipio'+index" class="form-control"></div>
                        </div>
                        <div class="row form-group">
                            <label for="direccion.estado" class="col-form-label col-sm-3">Estado</label>
                            <div class="col-sm-9">
                                <select v-model="tmp_direccion.estado" name="direccion.estado" id="direccion.estado" class="form-control">
                                    <option value="null">Selecciona un estado</option>
                                    <option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>
                                </select>
                            </div>            
                        </div>											
                        <div class="row form-group">
                            <label for="direccion_cp" class="col-form-label col-sm-3">CP</label>
                            <div class="col-sm-9"><input v-model="tmp_direccion.cp" type="number" name="direccion_cp" :id="'direccion_cp'+index" class="form-control"></div>
                        </div>
                        <div class="row form-group">
                            <label for="direccion_principal" class="col-form-label col-sm-3">Dirección principal</label>
                            <div class="col-sm-3">
                                <BSwitch v-model="tmp_direccion.principal" :id="'direccion_principal'+index" name="direccion_principal" texton="Sí" textoff="No" />
                            </div>
                            <label for="direccion_correspondencia" class="col-form-label col-sm-3">Dirección correspondencia</label>
                            <div class="col-sm-3">
                                <BSwitch v-model="tmp_direccion.correspondencia" :id="'direccion_correspondencia'+index" name="direccion_correspondencia" texton="Sí" textoff="No" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-4 offset-sm-4"><button class="btn btn-primary btn-block" @click="actualizar_direccion(tmp_direccion)">Guardar cambios</button></div>
                            <div class="col-sm-4"><button class="btn btn-danger btn-block" @click="eliminar_direccion(tmp_direccion, tmp_direccion.principal)">Eliminar</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-4 offset-sm-8"><button class="btn btn-block btn-success" @click="modal_agregar_direccion=true">Agregar dirección</button></div>
        </div>        
    </fieldset>    

    <Contacto :metodo_contacto="metodo_contacto" :ticket="ticket"/>

    <AgregarDireccion v-if="modal_agregar_direccion" :ticket="ticket" @close="cerrar_modal_direccion" :estados="estados"/>

  </div>
</template>

<script>

import Contacto from "./Contacto";
import AgregarDireccion from "./AgregarDireccion";
import apiClientes from '@/apps/clientes/api/clientes';
import BSwitch from '@/components/Form/Switch';

export default {
    components:{
        Contacto, AgregarDireccion, BSwitch,
    },
    props:{
        estados:{
            type:Array,
        },
        ticket:{
            type:Object,
        },
        metodo_contacto:{
            type:Array,
        },            
    },
    data(){
        return{
            direcciones:[],
            modal_agregar_direccion: false,
        }
        
    },
    methods:{
        async obtener_direcciones(){
            try {
                this.direcciones = (await apiClientes.listar_direcciones_tickets(this.ticket.cliente_id)).data;
                // this.$log.info('direcciones obtenidas', this.direcciones);
            } catch (e) {
                this.$log.info('err', e);
                this.$helper.showAxiosError(e, 'Error');

            }
        },
        async actualizar_direccion(direccion){
            try {
                // this.$log.info('antes de actualizar', direccion);
                await apiClientes.actuallizar_direccion_tickets(direccion.cliente_id, direccion.id, direccion);
                this.obtener_direcciones();
                this.$helper.showMessage('Contacto actualizado','La dirección se actualizo correctamente');
                // this.$log.info(data);
            } catch (e) {
                this.$log.info('err', e);
                this.$helper.showAxiosError(e, 'Error');
            }
        },
        async eliminar_direccion(direccion, principal){
            if (this.direcciones == 1)
                return this.$helper.showMessage('Error','Tienes que dejar al menos una dirección','error','alert');

            if (principal && (principal == 1 || principal === true))
                return this.$helper.showMessage('Error','No puedes borrar una dirección principal','error','alert')

            try {
                await apiClientes.eliminar_direccion_tickets(direccion.cliente_id, direccion.id);
                this.obtener_direcciones();
                this.$helper.showMessage('Exito!','Dirección eliminada');
            }catch(e) {
                this.$log.info('err', e)
                this.$helper.showAxiosError(e,'Error');
            }
        },
        cerrar_modal_direccion(){
            this.modal_agregar_direccion=false;
            this.obtener_direcciones();
        }
    },
    mounted(){
        // this.nuevo.cliente_nombre = this.ticket.cliente_nombre;
        this.obtener_direcciones();
    }
}
</script>

<style>

</style>